import { Link } from "gatsby"
import * as React from "react"
import { useTranslation } from 'react-i18next'
import './toppage.module.css'
import * as Style from './toppage.module.css'

import linkbanner from '../images/linkbanner_5_b.png'


function TopPage() {
  const { t, i18n } = useTranslation()

  return (
    <div className={Style.update}>

      {/* TOPICS */}
      {/* いまTOPICSなし

      <h6 className={Style.bgnv}>{t('key122')}</h6>

      <p>{t('key123-1')}
        <a className="text-decoration-none mx-1" href={t('key123-2')} target="_blank" rel="noopener noreferrer">JIMTOF2024</a>
        {t('key123-3')}<br />{t('key123-3-2')}</p>
      <p>{t('key123-4')}</p>
      <div className={Style.ref}>
        <a className="text-decoration-none mx-4" href={t('key123-6')} target="_blank" rel="noopener noreferrer">{t('key123-5')}</a>
      </div><br />
      <p>{t('key123-7')}</p>
      <div className={Style.ref}>
        <a className="text-decoration-none mx-4" href={t('key123-9')} target="_blank" rel="noopener noreferrer">{t('key123-8')}</a>
      </div>

      <br />
      *}



      {/* GAZIRU 製品紹介動画 */}
      <h5>{t('key17')}</h5>
      <br />
      <div className="moviep">
        <div className="movie"><iframe loading="lazy" title="GAZIRU + Dinolite" width="757" height="426" src="https://www.youtube.com/embed/hSXelFIz_Uo?feature=oembed" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div><br />
      <br />

      {/* 事業内容 */}
      <h5>{t('key2')}</h5>
      <p>{t('key21')}</p>
      <h6>{t('key22')}</h6>
      <p>{t('key23')}</p>
      <p><Link className="text-decoration-none mx-1" to={t('key7')}>{t('key24')}</Link></p><br />

      {/* 導入事例 */}
      <h5>{t('key3')}</h5>
      <h6>{t('key35')}</h6>
      <p>{t('key25')}</p>
      <h6>{t('key124')}</h6>
      <p>{t('key125')}</p>
      <h6>{t('key26')}</h6>
      <p>{t('key27')}</p>
      <h6>{t('key28')}</h6>
      <p>{t('key29')}</p>
      <p><Link className="text-decoration-none mx-1" to={t('key8')}>{t('key24')}</Link></p><br />

      {/* お知らせ */}
      <h5>{t('key30')}</h5>

      {/*replacekey_notice_begin*/}
      {/* TOPICS的なもの */}
      

      <br />

      {/* お知らせの特別残しておくやつ */}

      <h6>{t('key31')}</h6>
      <p>{t('key32')}</p>
      <a className="text-decoration-none mx-1" href="https://co-hr-innovation.jp/" target="_blank" rel="noopener noreferrer">{t('key33')}</a><br />
      <a className="text-decoration-none mx-1" href="https://www.nikkei.com/article/DGXMZO61733720Q0A720C2TJ1000/" target="_blank" rel="noopener noreferrer">{t('key34')}</a>
      {/*replacekey_notice_end*/}
      {/* お知らせの特別残しておくやつ ここまで */}

      <br /><br />

      {/* お知らせの履歴 */}
      {/*insertkey_notice*/}

      <p><Link className="text-decoration-none mx-1" to={t('key9')}>{t('key42')}</Link></p><br />

      <hr />
      <br />
      <p>{t('key99')}<Link className="text-decoration-none mx-1" to={t('key11')}>{t('key100')}</Link>{t('key101')}</p>
    </div>
  )
}

export default TopPage